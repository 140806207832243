import React from "react";

const MainContents = ({ leftColumnWidth }) => {
  return (
    <main className="px-4 py-2" style={{ marginLeft: leftColumnWidth }}>
      <h1>Systems Operations on AWS へようこそ！（<a href="https://aws.amazon.com/jp/training/classroom/systems-operations-on-aws/" target="blank">Systems Operations on AWS について</a>）</h1>
      <hr class="koji"/>
      <h3 id="prepare" class="anchor">初日の準備</h3>
      <div class="box">
        <h5>Webex</h5>
        <p>トレーニング期間中は配信環境として Webex を利用します。<br/>配信の他、「挙手」「グループワークでの会話」にも利用します。<br/>ログインする際には実名で（漢字・ローマ字問いません）ご登録をお願いします。<br/><a href="https://a.co/7SeNx7p" target="blank">Webex の使い方 (PDF)</a></p>
        <hr/>
        <h5>Rocket Chat</h5>
        <p>
          トレーニング期間中は<br/>
          =====<br/>
          ・インストラクター～参加者<br/>
          ・参加者～参加者<br/>
          =====<br/>
          のテキストコミュニケーションに<b>"Rocket Chat"</b>というツールを利用します。<br/>
          基本的に Webex のチャットではなく、Rocket Chat で質問したり会話をするように統一しましょう。
          <div class="blackboard-box">
            <p><b><span class="warn">Rocket Chat とは？</span></b></p>
            <p>Slack に似たツールで絵文字スタンプやスレッド投稿が可能です</p>
            <div class="chalk1"></div>
            <div class="chalk2"></div>
          </div>
        </p>
        <hr/>
        <h5>テキストのダウンロード</h5>
        <p>
          トレーニングで利用するテキストは、Gilmore Bookshelf という電子テキスト配布システムで参照します。<br/>
          下記URLから利用手順をダウンロードして、テキストの引き換えを行ってください。<br/>
          <div class="blackboard-box">
            <p><b><span class="warn">事前案内でテキストをダウンロード済みの方は、こちらの手順は不要です</span></b></p>
            <div class="chalk1"></div>
            <div class="chalk2"></div>
          </div>
          <a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">テキストの利用手順 (PDF)</a>
        </p>
        <hr/>
        <h5>ハンズオン環境へのログイン</h5>
        <p>
          トレーニングでは実際に AWS のアカウントを使用してハンズオンが行える QwikLabs というシステムを利用します。<br/>
          下記 URL の利用手順を参照して、ハンズオンが実施可能な状態にしておいてください。<br/>
          <a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/QwikLabsGuide.pdf" target="blank">ハンズオン利用手順 (PDF)</a><br/>
          <a href="https://aws.qwiklab.com/" target="blank">QwikLabs へログイン</a>
        </p>
        <hr/>
        <h5>付加資料に関して</h5>
        <p>
          本トレーニング内で適宜まとめております資料につきましては別途 PDF にて切り出しております。 (転送等はお控え下さいますよう、お願い致します。)<br/>
          <a href="https://sysops-appendix.s3.ap-northeast-1.amazonaws.com/SystemsOperationsOnAWS_Appendix.pdf" target="blank">トレーニング付加資料</a>
        </p>
        
      </div>
      <hr class="koji"/>
      <h3 id="agenda" class="anchor">アジェンダ</h3>
      <div class="box">
        <div class="blackboard-box">
          <p><b><span class="warn">下記はあくまで予定なので参考程度にお考えください</span></b></p>
          <p>みなさんが手を動かす時間を多めに取る予定です</p>
          <div class="chalk1"></div>
          <div class="chalk2"></div>
        </div>
        <h5>Day 1</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>1</td>
            <td>AWS でのシステム運用の概要</td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>アクセス管理</td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td>システム検出</td>
            <td><span class="point_box"><b>ラボ1</b></span>AWS Systems Manager と AWS Config を使って AWS リソースを監査する</td>
          </tr>
          <tr>
            <td>4</td>
            <td>リソースのデプロイと更新</td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>リソースデプロイの自動化</td>
            <td><span class="point_box"><b>ラボ2</b></span>Infrastructure as Code</td>
          </tr>
        </table>
        <hr/>
        <h5>Day 2</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>6</td>
            <td>リソースの管理</td>
            <td><span class="point_box"><b>ラボ3</b></span>Operations as code</td>
          </tr>
          <tr>
            <td>7</td>
            <td>高可用性システムの構成</td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td>スケーリングの自動化</td>
            <td></td>
          </tr>
          <tr>
            <td>9</td>
            <td>システム正常性のモニタリングと維持</td>
            <td><span class="point_box"><b>ラボ4</b></span>アプリケーションとインフラストラクチャをモニタリングする</td>
          </tr>
          <tr>
            <td>10</td>
            <td>データセキュリティとシステム監査</td>
            <td></td>
          </tr>
        </table>
        <hr/>
        <h5>Day 3</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>11</td>
            <td>安全性と耐障害性に優れたネットワーク運用</td>
            <td></td>
          </tr>
          <tr>
            <td>12</td>
            <td>マウント可能なストレージ</td>
            <td><span class="point_box"><b>ラボ5</b></span>AWS Backup を使ってアーカイブと復旧を自動化する</td>
          </tr>
          <tr>
            <td>13</td>
            <td>オブジェクトストレージ</td>
            <td></td>
          </tr>
          <tr>
            <td>14</td>
            <td>コストのレポート、アラート、最適化</td>
            <td><span class="point_box"><b>ラボ6</b></span>SysOps の最終ラボ</td>
          </tr>
          <tr>
            <td>エンディング</td>
            <td>コースのまとめ＆アンケート入力</td>
            <td></td>
          </tr>
        </table>
      </div>
      <hr class="koji"/>
      <h3 id="modules" class="anchor">参考リンク</h3>
      <div class="box">
        <h5>モジュール1（AWS でのシステム運用の概要）</h5>
        ・<a href="https://aws.amazon.com/jp/architecture/well-architected/" target="blank">AWS Well-Architected</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/latest/operational-excellence-pillar/welcome.html" target="blank">運用上の優秀性の柱 – AWS Well-Architected フレームワーク</a><br/>
        ・<a href="https://ap-northeast-1.console.aws.amazon.com/wellarchitected/home?region=ap-northeast-1#/dashboard" target="blank">AWS Well-Architected Tool（東京リージョン）</a><br/>
        <hr/>
        <h5>モジュール2（アクセス管理）</h5>
        ・<a href="https://aws.amazon.com/jp/register-flow/" target="blank">AWS アカウント作成の流れ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/general/latest/gr/root-vs-iam.html#aws_tasks-that-require-root" target="blank">ルートユーザー認証情報が必要なタスク</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/best-practices.html" target="blank">IAM でのセキュリティのベストプラクティス</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- IAM を運用する際には必見のページです。2022/07/14 に更新されました。<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/reference_policies_evaluation-logic.html#policy-eval-denyallow" target="blank">アカウント内でのリクエストの許可または拒否の決定</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/iam-role-passrole-assumerole/" target="blank">IAM ロールの PassRole と AssumeRole をもう二度と忘れないために絵を描いてみた＜外部サイト＞</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/accesskey-leak/" target="blank">【実録】アクセスキー流出、攻撃者のとった行動とその対策＜外部サイト＞</a><br/>
        <hr/>
        <h5>モジュール3（システム検出）</h5>
        ・<a href="https://docs.aws.amazon.com/ja_jp/systems-manager/latest/userguide/session-manager-working-with-install-plugin.html" target="blank">(オプション) AWS CLI 用の Session Manager プラグインをインストールする</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/config/latest/developerguide/example-query.html" target="blank">クエリ例</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-51_AWS_Summit_Online_2021_ManagementOnAws.pdf" target="blank">情シス向け運用ユースケース</a><br/>
        <hr/>
        <h5>モジュール4（リソースのデプロイと更新）</h5>
        ・<a href="https://docs.aws.amazon.com/ja_jp/general/latest/gr/aws_tagging.html" target="blank">AWS リソースのタグ付け</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/cost-allocation-tag/" target="blank">コスト配分タグを用いた効率的なコスト管理</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/organizations/latest/userguide/orgs_manage_policies_tag-policies.html" target="blank">タグポリシー</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/config/latest/developerguide/required-tags.html" target="blank">required-tags</a><br/>
        ・<a href="https://docs.aws.amazon.com/controltower/latest/userguide/2022-all.html#version-3.0" target="blank">AWS Control Tower landing zone version 3.0 (English Only)</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/startup/multi-accounts-and-control-tower/" target="blank">スタートアップにおけるマルチアカウントの考え方と AWS Control Tower のすゝめ</a><br/>
        <hr/>
        <h5>モジュール5（リソースデプロイの自動化）</h5>
        ・<a href="https://aws.amazon.com/jp/cdk/" target="blank">AWS クラウド開発キット</a><br/>
        ・<a href="https://aws.amazon.com/jp/serverless/sam/" target="blank">AWS サーバーレスアプリケーションモデル</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AWSCloudFormation/latest/UserGuide/best-practices.html#cross-stack" target="blank">クロススタック参照を使用して共有リソースをエクスポートします</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200826_AWS-BlackBelt_AWS-CloudFormation.pdf#page=82" target="blank">[AWS Black Belt Online Seminar] AWS CloudFormation (P. 82)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- ライフサイクル別のテンプレート管理について指針となる記載があります。<br/>
        ・<a href="https://aws.amazon.com/jp/cloudformation/resources/templates/" target="blank">AWS CloudFormation のテンプレート</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AWSCloudFormation/latest/UserGuide/cfn-sample-templates.html" target="blank">サンプルテンプレート</a><br/>
        ・<a href="https://aws.amazon.com/jp/quickstart/" target="blank">AWS クイックスタート</a><br/>
        <hr/>
        <h5>モジュール6（リソースの管理）</h5>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-20_CloudOperations_KMD14.pdf" target="blank">クラウドのシステム運用最前線︕上手く使いこなし運用を楽にしよう。</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/systems-manager/latest/userguide/sysman-manual-agent-install.html" target="blank">Linux 用の EC2 インスタンスに手動で SSM Agent をインストールする</a><br/>
        <hr/>
        <h5>モジュール7（高可用性システムの構成）</h5>
        ・<a href="https://aws.amazon.com/jp/elasticloadbalancing/features/#compare" target="blank">Elastic Load Balancing の特徴</a><br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/knowledge-center/elb-capture-client-ip-addresses/" target="blank">ELB の背後にあるウェブサーバーのログでクライアント IP アドレスをキャプチャするにはどうすればよいですか?</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/elasticloadbalancing/latest/application/load-balancer-cloudwatch-metrics.html#load-balancer-metrics-alb" target="blank">Application Load Balancer のメトリック</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/elasticloadbalancing/latest/network/load-balancer-cloudwatch-metrics.html#load-balancer-metrics-nlb" target="blank">Network Load Balancer メトリクス</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/elb_crosszone_load_balancing_default_value/" target="blank">ELBの種類によるクロスゾーン負荷分散のデフォルト値調べ</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20191105_AWS_Blackbelt_Route53_Hosted_Zone_A.pdf#page=42" target="blank">[AWS Black Belt Online Seminar] Amazon Route 53 Hosted Zone (P. 42)</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2022/06/amazon-route-53-ip-based-routing-dns-queries/" target="blank">Amazon Route 53 が DNS クエリの IP ベースのルーティングを発表</a><br/>
        <hr/>
        <h5>モジュール8（スケーリングの自動化）</h5>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20191002_AWS-Blakbelt_Auto_Scaling.pdf" target="blank">[AWS Black Belt Online Seminar] Amazon EC2 Auto Scaling & AWS AutoScaling</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20190306_AWS-Blackbelt-EC2Spot.pdf#page=18" target="blank">[AWS Black Belt Online Seminar] Amazon EC2スポットインスタンス (P. 18)</a><br/>
        <hr/>
        <h5>モジュール9（システム正常性のモニタリングと維持）</h5>
        ・<a href="https://speakerdeck.com/track3jyo/startup-monitoring-aws2022" target="blank">~スタートアップの人たちに捧ぐ~ 監視再入門 in AWS</a><br/>
        ・<a href="https://aws.amazon.com/jp/products/management-and-governance/use-cases/monitoring-and-observability/" target="blank">オブザーバビリティ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AWSEC2/latest/UserGuide/monitoring-system-instance-status-check.html" target="blank">インスタンスのステータスチェック</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/xray/latest/devguide/xray-gettingstarted.html" target="blank">AWS X-Ray を始める</a><br/>
        <hr/>
        <h5>モジュール10（データセキュリティとシステム監査）</h5>
        ・<a href="https://www.slideshare.net/nakatomoorg/frequently-asked-security-topic-for-aws-architecture-design" target="blank">セキュリティ設計の頻出論点 </a><br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/knowledge-center/analyze-logs-athena/" target="blank">Athena を使用して Amazon S3 サーバーのアクセスログを分析するにはどうすればよいですか?</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/guardduty/latest/ug/guardduty_finding-types-active.html" target="blank">検出結果タイプ</a><br/>
        ・<a href="https://www.nic.ad.jp/ja/basics/terms/cve.html" target="blank">CVEとは</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2021/11/amazon-inspector-continual-vulnerability-management/" target="blank">AWS が継続的な脆弱性管理のための新しい Amazon Inspector を発表</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/amazon-inspector-v2-released/" target="blank">[新サービス]大幅に使いやすくなりECRの脆弱性診断にも対応したAmazon Inspector v2が発表されました！ #reinvent＜外部サイト＞</a><br/>
        <hr/>
        <h5>モジュール11（安全性と耐障害性に優れたネットワーク運用）</h5>
        ・<a href="https://aws.amazon.com/jp/vpc/faqs/" target="blank">「Amazon VPC のよくある質問」</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20190313_AWS-BlackBelt-VPC.pdf" target="blank">[AWS Black Belt Online Seminar] Amazon Virtual Private Cloud(VPC)</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/global-infrastructure/" target="blank">AWS グローバルインフラストラクチャ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/acm/latest/userguide/managed-renewal.html" target="blank">ACM 証明書のマネージド更新</a><br/>
        <hr/>
        <h5>モジュール12（マウント可能なストレージ）</h5>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AWSEC2/latest/UserGuide/ebs-volumes-multi.html" target="blank">Amazon EBS マルチアタッチを使用した複数のインスタンスへのボリュームのアタッチ</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/B1-02.pdf" target="blank">AWS ストレージサービスの特性理解と選択指針 (P.24 / 33)</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/new-amazon-fsx-for-netapp-ontap/" target="blank">新サービス – Amazon FSx for NetApp ONTAP</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/new-amazon-fsx-for-openzfs/" target="blank">新機能 – Amazon FSx for OpenZFS</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/202205_AWS_Black_Belt_Amazon_FSx_for_Windows_File_Server.pdf" target="blank">Amazon FSx for Windows File Server: 2022 ver</a><br/>
        <hr/>
        <h5>モジュール13（オブジェクトストレージ）</h5>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AmazonS3/latest/userguide/restoring-objects.html" target="blank">アーカイブされたオブジェクトの復元</a><br/>
        ・<a href="https://aws.amazon.com/jp/s3/pricing/" target="blank">Amazon S3 の料金</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/s3-storage-class-choice-flowchart-2022-8/" target="blank">S3ストレージクラスの選択に迷った時に見るフローチャートを作ってみた(2022年8月版)＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/tv-tokyo/" target="blank">AWS 導入事例　株式会社テレビ東京</a><br/>
        <hr/>
        <h5>モジュール14（コストのレポート、アラート、最適化）</h5>
        ・<a href="https://aws.amazon.com/jp/blogs/news/cost-allocation-tag/" target="blank">コスト配分タグを用いた効率的なコスト管理</a><br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/technology/trusted-advisor/best-practice-checklist/" target="blank">「AWS Trusted Advisor ベストプラクティスチェックリスト」</a><br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/knowledge-center/rds-stop-seven-days/" target="blank">Amazon RDS インスタンスを 7 日以上停止する方法を教えて下さい。</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/operating-lambda-performance-optimization-part-2/" target="blank">Operating Lambda: パフォーマンスの最適化 – Part 2</a><br/>
        <hr/>
      </div>
      <hr class="koji"/>
      <h3 id="survey" class="anchor">アンケート</h3>
      <div class="box">
        <h5>アンケートの入力</h5>
        <ol>
          <li><a href="https://www.aws.training">https://www.aws.training</a> へアクセス</li>
          <li>「サインイン」※一般、もしくはAPNパートナー　でサインイン ボタンが異なります</li>
          <li>アカウント > トレーニングデータ　をクリック</li>
          <li><b>アーカイブ済み</b> タブ　で受講したトレーニングを表示</li>
          <li><b>評価</b>　をクリック</li>
          <li>アンケートとコメントを入力</li>
        </ol>
        <img src="survey.png" />
      </div>
      <hr class="koji"/>
      <h3 id="faq" class="anchor">FAQ</h3>
      <div class="box">
        <h5>ハンズオン（Qwiklabs）</h5>
        Q. トレーニング期間中、ハンズオン環境はいつまで利用できますか？<br/>
        &nbsp;&nbsp;・トレーニング最終日の 23 時まで利用可能です。<br/>
        &nbsp;&nbsp;・ただし、3回 / Lab / 人 の制限がありますので、お気をつけください。<br/><br/>
        Q. ハンズオン環境のタイマーのような表示はなんですか？<br/>
        &nbsp;&nbsp;・ハンズオン環境は一度起動すると AWS アカウントが払い出され、タイマーによってカウントダウンが始まります。<br/>
        &nbsp;&nbsp;・タイマーが 00:00:00 になると起動したハンズオン環境へのサインインができなくなり、起動した AWS アカウント上のリソースがワイプ (削除)されます<br/><br/>
        Q. ハンズオン環境で<b><font color="red">ラボ終了</font></b>をクリックするとどうなりますか？<br/>
        &nbsp;&nbsp;・ハンズオン環境へのサインインができなくなり、起動したAWSアカウント上のリソースがワイプ (削除)されます。
        <hr/>
        <h5>トレーニングテキスト</h5>
        Q. トレーニングテキストはいつまで参照できますか？<br/>
        &nbsp;&nbsp;・ダウンロードから730日間参照できます。<br/><br/>
        Q. トレーニングテキストは印刷できますか？<br/>
        &nbsp;&nbsp;・Bookshelf というアプリケーションを利用することで印刷が可能です。ただし、一度に印刷できるのは 50 ページの制限があります。無断配布は禁じられております。<br/>
        &nbsp;&nbsp;・<a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">Bookshelfの入手方法はこちら (PDF)</a>
        <hr/>
        <h5>Rocket Chat</h5>
        Q. チャットの内容はいつでも参照できますか？<br/>
        &nbsp;&nbsp;・いいえ、トレーニング最終日の翌日を目処に削除します。
      </div>
    </main>
  );
};

export default MainContents;
